<portal-work-in-progress *ngIf="isInMaintenance"/>

<ng-container *ngIf="!isInMaintenance">
  <portal-initial-loader/>
  <portal-app-error></portal-app-error>
  <portal-header *ngIf="!initialLoader.isShown"></portal-header>
  <router-outlet></router-outlet>
  <portal-toasts></portal-toasts>
  <portal-loading-overlay/>
</ng-container>
