import { Component } from '@angular/core';
import {
  faAbacus,
  faAtom,
  faBookSpells,
  faBroadcastTower,
  faCauldron,
  faChartNetwork,
  faDraftingCompass,
  faHouseNight,
  faIndustryAlt,
  faMicroscope,
  faPie,
  faSeedling,
  faSoup,
  faStars,
  faTools,
  faTriangleMusic,
  faUfo,
  faUmbrellaBeach,
  faUserAstronaut
} from '@fortawesome/pro-solid-svg-icons';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { random } from 'lodash-es';
import { IconDefinition } from '@fortawesome/pro-regular-svg-icons';


type Message = { text: string, icon: IconDefinition };

const MESSAGES: Message[] = [
  { text: 'Scheduled system sparkles in progress.', icon: faStars},
  { text: 'Discovering new skills galaxies.', icon: faUserAstronaut},
  { text: 'Exploring new potential activity sources.', icon: faUfo},
  { text: 'Researching potential enhancements', icon: faMicroscope },
  { text: 'Extrapolating exponential experiences.', icon: faChartNetwork},
  { text: 'Apologies, our janitors are running system maintenance.', icon: faTools},
  { text: 'System calibration in progress', icon: faTriangleMusic},
  { text: 'Running the numbers.', icon: faAbacus},
  { text: 'Cooking up system enhancements.', icon: faSoup},
  { text: 'Building new skills and activity options.', icon: faBookSpells},
  { text: 'The system is enjoying a well earned rest.', icon: faHouseNight},
  { text: 'Expanding our horizons.', icon: faDraftingCompass},
  { text: 'Growing new features and enhancements.', icon: faSeedling},
  { text: 'Cooking up some new learning wizardry.', icon: faCauldron},
  { text: 'System analysis in progress.', icon: faAtom},
  { text: 'Scheduled downtime in progress.', icon: faBroadcastTower},
  { text: 'Cooking up new features.', icon: faPie},
  { text: 'Manufacturing new capabilities.', icon: faIndustryAlt},
  { text: 'This platform is taking a scheduled break.', icon: faUmbrellaBeach}
]

@Component({
  selector: 'portal-work-in-progress',
  standalone: true,
  imports: [CommonModule, FontAwesomeModule],
  templateUrl: './work-in-progress.component.html',
  styleUrls: ['./work-in-progress.component.scss']
})
export class WorkInProgressComponent {
  message: Message = MESSAGES[random(0, MESSAGES.length)];
}
