import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppErrorService } from './app-error.service';
import { ContactSupportComponent } from "../contact-support/contact-support.component";

@Component({
  selector: 'portal-app-error',
  standalone: true,
  imports: [CommonModule, ContactSupportComponent],
  templateUrl: './app-error.component.html',
  styleUrl: './app-error.component.scss'
})
export class AppErrorComponent {

  constructor(private errorService: AppErrorService) {
  }

  get shouldShow() {
    return this.errorService.visible;
  }

  doReload() {
    window.location.reload();
  }

}
