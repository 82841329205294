import { Injectable } from '@angular/core';
import type { DefaultErrorShape } from '@trpc/server';

// this can be a singleton since it doesn't store state
@Injectable({
  providedIn: 'root'
})
export class ErrorSilencerService {

  constructor() {} 

  private errorIsTrpc(error: Error | DefaultErrorShape): error is DefaultErrorShape {
    return (error as DefaultErrorShape).data !== undefined;
  }

  private isUnauthorized(error: DefaultErrorShape) {
    return error.data.httpStatus === 401;
  } 

  shouldSilenceUnauthorized(error: Error | DefaultErrorShape) {
    return this.errorIsTrpc(error) && this.isUnauthorized(error as DefaultErrorShape);
  }


}
