import { Injectable } from '@angular/core';
import { ToastService } from './toast.service';

@Injectable({
  providedIn: 'root'
})
export class RefreshToastService extends ToastService {

  constructor() {
    super();
  }

  actionWrapper() {
    window.location.reload(); 
  }
}
