interface Element {
  readonly id: string;
}

/**
 * ngFor trackBy setting.
 * @param index
 * @param content
 */
export function trackById(index: number, element: Element): string {
  return element.id;
}

export function trackByIndex(index: number): string {
  return `${index}`;
}
