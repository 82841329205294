import { Routes } from '@angular/router';

export const APP_ROUTES: Routes = [
  {
    path: '',
    pathMatch: 'full',
    loadComponent: () => import('./root-page/root-page.component').then(mod => mod.RootPageComponent)
  },
  {
    path: 'providers/learnswell',
    loadChildren: () => import('./providers/learnswell/internal.routes').then(mod => mod.INTERNAL_ROUTES)
  },
  {
    path: 'providers/nbcrna',
    loadChildren: () => import('./providers/nbcrna/nbcrna.routes').then(mod => mod.NBCRNA_ROUTES)
  },
  {
    path: 'providers/silkoak',
    loadChildren: () => import('./providers/silkoak/silkoak.routes').then(mod => mod.SILKOAK_ROUTES)
  },
  {
    path: 'providers/silkoak-bn',
    loadChildren: () => import('./providers/silkoak/silkoak-bn.routes').then(mod => mod.SILKOAK_BN_ROUTES)
  },
  {
    path: 'providers/abfas',
    loadChildren: () => import('./providers/abfas/abfas.routes').then(mod => mod.ABFAS_ROUTES)
  },
  {
    path: 'providers/iars-eeg',
    loadChildren: () => import('./providers/iars-eeg/iars-eeg.routes').then(mod => mod.IARS_EEG_ROUTES)
  },
  {
    path: 'providers/abpm',
    loadChildren: () => import('./providers/abpm/abpm.routes').then(mod => mod.ABPM_ROUTES)
  },
  {
    path: 'providers/neomo',
    loadChildren: () => import('./providers/neomo/neomo.routes').then(mod => mod.NEOMO_ROUTES)
  },
  { path: '**', loadComponent: () => import('./not-found/not-found.component').then(mod => mod.NotFoundComponent) },
];
